import React, { Component } from 'react'
import Membership from './Membership'
import MembershipPage from './MembershipPage'
import UserDetails from './UserDetails'
import Payment from './Payment'
import Success from './Success'
import { getOrganizationById, getOrganizationDetails, getOrganizationEvents, getPackages } from '../api/apiHelper'
import NotFound from './NotFound'
import Loading from './Loading'
import { getMember } from '../api/apiHelper'
import { removeTokens, saveToken, getAccessToken, getRefreshToken } from './shared/Token';
import Upload from './Upload'
import VerifyError from './VerifyError'
import { CONFIRM_DUPLICATE_MEMBERSHIP, DATE_SELECT, EVENTS, LOADING, NOT_FOUND, PAYMENT, SELECT_MEMBERSHIPS, SUCCESS, TICKET_COUNT_SELECT, UPLOAD_DOCUMENT, USER_DETAILS, VERIFY, VERIFY_FAIL, SELECT_MEMBERSHIP_PAGE, FORM_PAGE } from './shared/Steps'
import DateSelect from './DateSelect'
import ConfirmDuplicateMembership from './ConfirmDuplicateMembership'
import Events from './Events'
import TicketCountSelect from './TicketCountSelect'
import VerifyTicket from './VerifyTicket'
import FormPage from './FormPage'
import TicketForm from './TicketForm'

export default class Main extends Component {
  constructor(props) {
    super(props)
    const params = new URLSearchParams(window.location.search);
    const token = params.get("view")

    var org_id
    var eventId
    var occurId
    var packageId
    let scope = this.props.scope

    if (scope === 'ticket') {
      // "/2517/events" or "/2517/events/123"
      let org = window.location.pathname.split('/')
      org_id = org[1]
      eventId = org[3]
      occurId = org[5]

    } else {
      let org = window.location.pathname.split('/')
      org_id = org[1]
      packageId = org[3]
    }

    this.state = {
      eventId: eventId,
      packageId: packageId,
      scope: scope,
      step: 6,
      first_name: null,
      last_name: null,
      personal_identification_number: null,
      email: null,
      mobile_number: null,
      street_address: "",
      co_address: "",
      zip_code: "",
      city: "",
      meta: null,
      password: "",
      repeat_password: "",
      org_id: org_id,
      packages: null,
      selected_package: null,
      token: token,
      valid_token: false,
      requiresVerification: null,
      verificationError: null,
      selected_ticket: null,
      selected_occurrence: Number(occurId),
      formTicketIndex: 0,
      formResult: []
    }
  }


  componentDidMount() {
    var access_token = getAccessToken()
    var refresh_token = getRefreshToken()

    // If we have access token via URL
    if (this.state.token && refresh_token === undefined) {

      getMember(this.state.token, (member) => {
        if (member && member.id) {
          if (access_token === undefined) {
            saveToken({
              access_token: this.state.token,
              expires_in: "3600000"
            })
          }
          this.setState({
            valid_token: true,
            first_name: member.first_name,
            last_name: member.last_name,
            personal_identification_number: member.personal_identification_number,
            email: member.email,
            street_address: member.street_address,
            zip_code: member.zip_code,
            city: member.city,
          })
        }

        window.history.replaceState(null, null, window.location.pathname);
      }, (error) => {
        window.history.replaceState(null, null, window.location.pathname);
        removeTokens()
      })
    } else if (access_token && refresh_token) {
      getMember(access_token, (member) => {
        if (member && member.id) {
          this.setState({
            valid_token: true,
            first_name: member.first_name,
            last_name: member.last_name,
            personal_identification_number: member.personal_identification_number,
            email: member.email,
            street_address: member.street_address,
            zip_code: member.zip_code,
            city: member.city,
          })
        }
      }, (error) => {
        removeTokens()
      })
    }

    if (this.state.org_id) {

      if (this.state.scope === 'ticket') {

        getOrganizationEvents(this.state.org_id, (events) => {
          if (!events || events.error) {
            this.setState({ step: NOT_FOUND })
            return
          }
          if (events && this.state.selected_occurrence) {
            this.setState({ events: events, step: TICKET_COUNT_SELECT })
          } else {
            this.setState({ events: events, step: EVENTS })
          }
        }, (error) => {
          this.setState({ step: NOT_FOUND })
        })

      } else {
        getPackages(this.state.org_id, (packages) => {
          if (!packages || packages.error) {
            this.setState({ step: NOT_FOUND })
            return
          }
          if (this.state.packageId) {
            this.setState({ packages: packages, step: SELECT_MEMBERSHIP_PAGE })

          } else {
            this.setState({ packages: packages, step: SELECT_MEMBERSHIPS })
          }

        }, (error) => {
          this.setState({ step: NOT_FOUND })
        })
      }

      getOrganizationDetails(this.state.org_id, (result) => {
        this.setState({ organization_details: result })
      }, (error) => {
        console.log("error getOrganizationDetails", error)
      })

      getOrganizationById(this.state.org_id, (organization) => {
        this.setState({ organization: organization })
      }, (error) => {
        console.log("error getOrganizationById", error)
      })

    } else {
      this.setState({ step: NOT_FOUND })
    }
  }
  // go back to previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  }

  // proceed to the next step
  nextStep = (jumpStep) => {
    if (jumpStep) {
      return this.setState({ step: jumpStep });
    }
    const { step, token, valid_token } = this.state;

    if (token && valid_token) {
      this.setState({ step: step + 2 });
    } else {
      this.setState({ step: step + 1 });
    }
  }

  handleFormChange = (ticketFormId, index, result, ticketId) => {
    let formResults = this.state.formResult

    let existingFormResult = formResults.find((formResult) => formResult.index === index)
    if (existingFormResult) {
      existingFormResult.result = result
    } else {
      formResults.push({index: index, result: result})
    }

    let meta = this.state.meta
    let ticketMeta = meta.tickets.find((ticket) => ticket.id === ticketId)
    ticketMeta.ticket_form = {
      id: ticketFormId,
      form_result: formResults
    }
    this.handleChange('meta', meta)

    this.setState({formResult: formResults})
  }

  // Handle fields change
  handleChange = (key, value, callback = null) => {
    this.setState({ [key]: value },
      () => {
        if (callback) {
          callback()
        }
      }
    );
  }

  handleEventUpdate = (updatedEvent) => {
    // Update the event state in the Main component
    this.setState((prevState) => {
      const updatedEvents = prevState.events.map((event) => {
        if (event.id === updatedEvent.id) {
          return updatedEvent;
        }
        return event;
      });

      return { events: updatedEvents };
    });
  };

  render() {

    const { step } = this.state;

    const { first_name, last_name, password, repeat_password, personal_identification_number, email, mobile_number, street_address, co_address, zip_code, city,
      packages, selected_package, packageId, meta, organization_details, org_id, organization, requiresVerification,
      verificationError, canCompletePackageResult, verifyTicketResult, events, selected_ticket, selected_occurrence, scope, forms, formTicketIndex, formResult } = this.state;

    const values = {
      packages, packageId, events, selected_ticket, selected_occurrence, selected_package, meta, organization_details, org_id, organization, requiresVerification, verificationError, canCompletePackageResult, verifyTicketResult, scope, forms, formTicketIndex, formResult, 
      first_name, last_name, password, repeat_password, personal_identification_number, email, mobile_number, street_address, co_address, zip_code, city,
    }

    document.title = 'Stuk Pay'

    switch (step) {
      case EVENTS:
        return <Events
          nextStep={this.nextStep}
          handleChange={this.handleChange}
          eventId={this.state.eventId}
          values={values}
          handleEventUpdate={this.handleEventUpdate}
        />

      case TICKET_COUNT_SELECT:
        return <TicketCountSelect
          prevStep={this.prevStep}
          nextStep={this.nextStep}
          handleChange={this.handleChange}
          values={values}
          handleEventUpdate={this.handleEventUpdate}
        />

      case VERIFY:
        return <VerifyTicket
          prevStep={this.prevStep}
          nextStep={this.nextStep}
          handleChange={this.handleChange}
          values={values}
        />

      case SELECT_MEMBERSHIPS:
        return (
          <Membership
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        )

      case SELECT_MEMBERSHIP_PAGE:
        return (
          <MembershipPage
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        )

      case USER_DETAILS:
        return (
          <UserDetails
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
          />
        )
      case PAYMENT:
        return (
          <Payment
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        )
      case SUCCESS:
        return (
          <Success />
        )
      case NOT_FOUND:
        return (
          <NotFound />
        )
      case LOADING:
        return (
          <Loading />
        )
      case UPLOAD_DOCUMENT:
        return (
          <Upload
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        )
      case VERIFY_FAIL:
        return (
          <VerifyError
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        )
      case DATE_SELECT:
        return (
          <DateSelect
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        )

      case CONFIRM_DUPLICATE_MEMBERSHIP:
        return (
          <ConfirmDuplicateMembership
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        )

      case FORM_PAGE:
        return (
          <TicketForm
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleFormChange={this.handleFormChange}
            handleChange={this.handleChange}
            values={values}
          />
        )
      default:
      // do nothing
    }
  }
}
